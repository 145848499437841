import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//获取博主反馈
export const getFeedbackList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/feedback/list",
  });

//导出博主反馈
export const exportFeedbackList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/feedback/list`,
  });

//删除反馈
export const delFeedback = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/feedback/del",
  });

//获取博主推荐记录
export const getBloggerPushLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/push/log/list",
  });

//导出博主推荐记录
export const exportBloggerPushLogList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/push/log/export`,
  });

//获取博主指令轮盘数据列表
export const getBloggerGameWheelList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/game/wheel/list",
  });

//导出博主指令轮盘数据列表
export const exportBloggerGameWheelList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/game/wheel/list`,
  });

//获取博主默认消息列表
export const getBloggerDefaultCallList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/defaultcall/list",
  });

//导出博主默认消息列表
export const exportBloggerDefaultCallList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/defaultcall/list`,
  });

//获取博主价格设置列表
export const getBloggerPriceList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/price/list",
  });

//获取博主价格日志列表
export const getBloggerPriceLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/price/log/list",
  });

//导出博主价格设置列表
export const exportBloggerPriceList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/price/export`,
  });

//博主充值推送记录
export const getBloggerRechargePushList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/recharge/push/list",
  });

//导出博主充值推送记录
export const exportBloggerRechargePushList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/recharge/push/export`,
  });

//获取博主引导列表
export const getBloggerGuideList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/guide/list",
  });

//导出博主引导列表
export const exportBloggerGuideList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/guide/export`,
  });

//保存博主引导
export const saveBloggerGuide = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/guide/save",
  });

//删除博主引导
export const deleteBloggerGuide = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/guide/delete",
  });

//设置博主引导状态
export const setBloggerGuideStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/guide/status/set",
  });

//获取博主引导日志
export const getBloggerGuideLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/guide/log/list",
  });

//导出博主引导日志
export const exportBloggerGuideLogList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/guide/log/export`,
  });

//获取博主活跃时长列表
export const getActiveDurationList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/active/duration/list",
  });

//导出博主活跃时长列表
export const exportActiveDurationList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/active/duration/export`,
  });

//获取博主活跃记录列表
export const getBloggerActiveLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/active/log/list",
  });

//导出博主活跃记录列表
export const exportBloggerActiveLogList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/active/log/export`,
  });

//获取博主回复效率统计
export const getBloggerReplyCountList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/blogger_reply_count/list",
  });

//导出博主回复效率统计
export const exportBloggerReplyCountList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/blogger_reply_count/export`,
  });

//获取博主通话效率统计
export const getBloggerCallCountList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/blogger_call_count/list",
  });

//导出博主通话效率统计
export const exportBloggerCallCountList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/blogger_call_count/export`,
  });

//获取博主帖子推荐配置列表
export const getBloggerRecommendList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/recommend/list",
  });

//获取博主帖子推荐配置导出
export const getBloggerRecommendExport = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/recommend/export`,
  });

//设置博主帖子状态
export const setBloggerRecommendStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/recommend/status",
  });

//保存博主帖子推荐配置
export const saveBloggerRecommend = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/recommend/save",
  });

//获取博主帖子推荐记录列表
export const getBloggerRecommendLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/recommend_log/list",
  });

//导出博主帖子推荐记录列表
export const getBloggerRecommendLogExport = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/recommend_log/export`,
  });

//获取博主帖子推荐用户列表
export const getBloggerRecommendUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/recommend_user/list",
  });

//导出博主帖子推荐用户列表
export const getBloggerRecommendUserExport = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/recommend_user/export`,
  });

//获取博主设置列表
export const getBloggerSetList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/set/list",
  });

//保存博主设置
export const saveBloggerSet = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/set/save",
  });

//设置私密博主
export const setBloggerPrivacy = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/privacy/set",
  });

//设置伴侣博主
export const setBloggerCompanion = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/companion/set",
  });

//多巴博主标签配置列表
export const getBloggerRiskLabelList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/risk/label/list",
  });

//删除多巴博主标签配置
export const deleteBloggerRiskLabel = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/risk/label/delete",
  });

//保存多巴博主标签配置
export const saveBloggerRiskLabel = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/risk/label/save",
  });

//博主伴侣列表
export const getBloggerCmpanionList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/companion/list",
  });

//导出伴侣博主列表
export const getBloggerCompanionExport = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/companion/export`,
  });

//视频开关时长列表
export const getVideoSwitchDurationList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/video/switch/duration/list",
  });

//导出视频开关时长列表
export const getVideoSwitchDurationExport = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/video/switch/duration/export`,
  });

//视频开关记录列表
export const getVideoSwitchLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/video/switch/log/list",
  });

//导出视频开关记录列表
export const getVideoSwitchLogExport = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/blogger/video/switch/log/export`,
  });

//订阅关系列表
export const dyUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/dy/user/list",
  });

//订阅记录列表
export const dyLogList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/dy/log/list",
  });

//订阅奖励列表
export const dyAwardList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/dy/award/list",
  });

//订阅关系取消
export const dyUserCancel = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/dy/cancel",
  });

//代运营管理员列表
export const manageAssistList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/assist/list",
  });

//代运营博主列表
export const assistBloggerList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/assist/blogger/list",
  });

//代运营博主保存
export const assistBloggerSave = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/assist/blogger/save",
  });

//代运营博主删除
export const assistBloggerRemove = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/assist/blogger/remove",
  });

//博主匹配记录
export const assistBloggerUserMatch = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/user/match/log/list",
  });

//通话邀请列表
export const inviteCcallList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/invite/call/list",
  });

//每日积分-列表
export const dayScoreList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/day/score/list",
  });
